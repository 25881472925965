/**
 * @ComponentFor MobileRowLayout
 */

import React, { useEffect } from 'react';
import { epiPropertyValue } from '@avensia/scope-episerver';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import MobileRowLayout from './MobileRowLayout.type';
import Image, { Ratio } from 'Shared/Image/Ratio';
import { pixelsToUnit, white } from 'Shared/Style';
import Link from 'Shared/Link';
import { Style } from '@glitz/type';
import { trackPromotionImpression, trackPromotionClick } from 'TrackingInformation';

type ConnectStateType = {
  currentBreakpoint: number;
};

type PropType = MobileRowLayout & ConnectStateType;

const MobileRowLayout = (props: PropType) => {
  useEffect(() => {
    trackPromotionImpression(props.promotionImpression);
  }, []);

  const trackPromoClick = () => trackPromotionClick(props.promotionImpression);

  const isMobile = props.currentBreakpoint < 4;
  const spacingBetween = epiPropertyValue(props.spacingBetweenBlocks);
  const imageStyle: Style = { objectFit: 'cover' };

  const noPlacementSelected1 =
    !epiPropertyValue(props.textPlacementBottom) && !epiPropertyValue(props.textPlacementTop);
  const bothPlacementSelected1 =
    epiPropertyValue(props.textPlacementBottom) && epiPropertyValue(props.textPlacementTop);
  const centeredText1 = noPlacementSelected1 || bothPlacementSelected1;

  const noPlacementSelected2 =
    !epiPropertyValue(props.textPlacementBottom2) && !epiPropertyValue(props.textPlacementTop2);
  const bothPlacementSelected2 =
    epiPropertyValue(props.textPlacementBottom2) && epiPropertyValue(props.textPlacementTop2);
  const centeredText2 = noPlacementSelected2 || bothPlacementSelected2;

  const noPlacementSelected3 =
    !epiPropertyValue(props.textPlacementBottom3) && !epiPropertyValue(props.textPlacementTop3);
  const bothPlacementSelected3 =
    epiPropertyValue(props.textPlacementBottom3) && epiPropertyValue(props.textPlacementTop3);
  const centeredText3 = noPlacementSelected3 || bothPlacementSelected3;

  const textStyle1: Style = {
    position: 'absolute',
    top: centeredText1 ? '50%' : epiPropertyValue(props.textPlacementTop) ? pixelsToUnit(20) : 'unset',
    bottom: centeredText1 ? 'unset' : epiPropertyValue(props.textPlacementBottom) ? 0 : 'unset',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 'bold',
    textAlign: 'center',
    color: !!epiPropertyValue(props.textColorHexcode1) ? epiPropertyValue(props.textColorHexcode1) : white,
    fontSize: epiPropertyValue(props.fontSize1) + 'vw',
  };

  const textStyle2: Style = {
    position: 'absolute',
    top: centeredText2 ? '50%' : epiPropertyValue(props.textPlacementTop2) ? pixelsToUnit(20) : 'unset',
    bottom: centeredText2 ? 'unset' : epiPropertyValue(props.textPlacementBottom2) ? 0 : 'unset',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 'bold',
    textAlign: 'center',
    color: !!epiPropertyValue(props.textColorHexcode2) ? epiPropertyValue(props.textColorHexcode2) : white,
    fontSize: epiPropertyValue(props.fontSize2) + 'vw',
  };

  const textStyle3: Style = {
    position: 'absolute',
    top: centeredText3 ? '50%' : epiPropertyValue(props.textPlacementTop3) ? pixelsToUnit(20) : 'unset',
    bottom: centeredText3 ? 'unset' : epiPropertyValue(props.textPlacementBottom3) ? 0 : 'unset',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 'bold',
    textAlign: 'center',
    color: !!epiPropertyValue(props.textColorHexcode3) ? epiPropertyValue(props.textColorHexcode3) : white,
    fontSize: epiPropertyValue(props.fontSize3) + 'vw',
  };

  return (
    <>
      {isMobile && (
        <Container>
          {!!epiPropertyValue(props.imageUrl1) && (
            <LinkStyled
              css={spacingBetween && { padding: { x: pixelsToUnit(5), y: 0 } }}
              to={epiPropertyValue(props.linkUrl1)}
            >
              <ImageStyled
                src={epiPropertyValue(props.imageUrl1).url}
                alt={epiPropertyValue(props.altText1)}
                ratio={Ratio.OneToOne}
                imageStyle={imageStyle}
                onClick={trackPromoClick}
                lazy
              />
              <ImageText css={textStyle1}>
                {!!epiPropertyValue(props.textInImage1) && epiPropertyValue(props.textInImage1)}
              </ImageText>
            </LinkStyled>
          )}
          {!!epiPropertyValue(props.imageUrl2) && (
            <LinkStyled
              to={epiPropertyValue(props.linkUrl2)}
              css={spacingBetween && { padding: { x: pixelsToUnit(5), y: 0 } }}
            >
              <ImageStyled
                src={epiPropertyValue(props.imageUrl2).url}
                alt={epiPropertyValue(props.altText2)}
                ratio={Ratio.OneToOne}
                imageStyle={imageStyle}
              />

              <ImageText css={textStyle2}>
                {!!epiPropertyValue(props.textInImage2) && epiPropertyValue(props.textInImage2)}
              </ImageText>
              <ImageText css={textStyle2}>
                {!!epiPropertyValue(props.textInImage2) && epiPropertyValue(props.textInImage2)}
              </ImageText>
            </LinkStyled>
          )}
          {!!epiPropertyValue(props.imageUrl3) && (
            <LinkStyled
              to={epiPropertyValue(props.linkUrl3)}
              css={spacingBetween && { padding: { x: pixelsToUnit(5), y: 0 } }}
            >
              <ImageStyled
                src={epiPropertyValue(props.imageUrl3).url}
                alt={epiPropertyValue(props.altText3)}
                ratio={Ratio.OneToOne}
                imageStyle={imageStyle}
              />
              <ImageText css={textStyle3}>
                {!!epiPropertyValue(props.textInImage3) && epiPropertyValue(props.textInImage3)}
              </ImageText>
            </LinkStyled>
          )}
        </Container>
      )}
    </>
  );
};

export default connect(
  (state): ConnectStateType => ({
    currentBreakpoint: state.currentBreakpoint,
  }),
)(MobileRowLayout);

const ImageStyled = styled(Image, {
  flexGrow: 1,
  height: 'auto',
  paddingLeft: pixelsToUnit(10),
});

const LinkStyled = styled(Link, {
  flexGrow: 1,
  position: 'relative',
});

const ImageText = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontWeight: 'bold',
  textAlign: 'center',
});

const Container = styled.div({
  display: 'flex',
  width: '100%',
});
